export const options = {
    title: "Cursul de Patiser s-a încheiat",
    category: "PROGRES",
    category_link: "/projectsprogress",
    id: 88,
  };
  
  export default function Post() {
    return (
      <>
        <div class="accordion-content mt-4">
          <p class="mb-3 w-full md:text-lg">
          Cea de-a treia grupă de patiser a susținut examenul și a obținut calificarea, finalizându-se astfel cursul susținut de MARINEX SRL în cadrul proiectului ”Creșterea ocupării, precum și îmbunătățirea nivelului de competențe al tinerilor NEETs”.
          </p>
          <p class="mb-3 w-full md:text-lg">
          Le mulțumi tuturor pentru participare și le dorim mult succes în valorificarea acestei calificări.
          </p>
        </div>
      </>
    );
  }
  
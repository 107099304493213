export const options = {
    title: "Recrutările pentru grupul țintă continuă în luna octombrie 2023",
    category: "PROGRES",
    category_link: "/projectsprogress",
    id: 86,
  };
  
  export default function Post() {
    return (
      <>
        <div class="accordion-content mt-4">
          <p class="mb-3 w-full md:text-lg">
          Recrutările pentru grupul țintă al proiectului se apropie de final. Așadar, dacă dorești să beneficiezi de oportunitățile oferite de proiect, grăbește-te! Contactează-ne pentru a afla ce cursuri mai au locuri disponibile.
          </p>
          <p class="mb-3 w-full md:text-lg">
          Activitățile proiectului se adresează tinerilor NEETs din regiunea centru cu domiciliul în Alba, Brașov, Covasna, Harghita, Mureș sau Sibiu și cu vârsta între 16 și 29 de ani. Toate cursurile se desfășoară online, iar participanții vor primi câte o tabletă.
          </p>
          <p class="mb-3 w-full md:text-lg">
          Contact – 0739.162.605
          </p>
        </div>
      </>
    );
  }
  
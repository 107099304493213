
export const options = {
	"title": "Cursul de Patiser continuă",
	"category": "PROGRES",
	"category_link": "/projectsprogress",
	"id": 74
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg">A doua grupă continuă activitatea practică în cadrul cursului. Ei vor susține examenul de calificare în data de 17 august.</p>
                  <p class="mb-3 w-full md:text-lg">Cea de-a treia grupă de curs a început activitatea cu partea teoretică. Examenul este programat pentru luna octombrie.</p>
                  <p class="mb-3 w-full md:text-lg">Cursul de patiser se desfășoară în cadrul proiectului ”Creșterea ocupării, precum și îmbunătățirea nivelului de competențe al tinerilor NEETs”, iar participarea este gratuită pentru membri grupului țintă.</p>
                  </div>
	</>
	)
}

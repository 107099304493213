export const options = {
	"title": "ARION MANAGEMENT SRL – servicii de consultanță și management",
	"category": "AFACERI",
	"category_link": "/projectsbusiness",
	"id": 98,
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg"><b>ARION MANAGEMENT SRL</b> – partenerul dvs. de încredere pentru soluții de consultanță și management.</p>
                  <p class="mb-3 w-full md:text-lg">Fie că sunteți o afacere nouă, o întreprindere în creștere sau o corporație consolidată, suntem aici pentru a vă oferi soluții adaptate și inovatoare care să vă ajute să vă atingeți potențialul maxim. Cu o abordare orientată spre client și angajamentul nostru față de excelență, suntem gata să vă susținem în fiecare etapă a călătoriei dvs. în lumea afacerilor.”</p>
                  <ol class="list-decimal list-inside md:px-5 mb-4 text-base md:text-lg">
                    <li class="mb-3 w-full md:text-lg list-disc">Servicii de consultanță conform contract;</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Verificare eligibilitate;</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Întocmire documentație;</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Analiză tehnico-financiară.</li>
                  </ol>
                  <p class="mb-3 w-full md:text-lg">Mai multe informații la nr. de telefon 0745 357 905, email office.arion.management@gmail.com sau pe <a class="items-center text-lg underline hover:text-red-500" src="https://arion-management.ro/">www.arion-management.ro</a>.</p>
                  <p class="mb-3 w-full md:text-lg">Le urăm mult succes în implementarea planului de afaceri!

</p>
                  </div>
	</>
	)
}

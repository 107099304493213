export const options = {
	"title": "Novafin Star SRL – Lucrări de vopsitorie, zugrăveli și montări de geamuri",
	"category": "AFACERI",
	"category_link": "/projectsbusiness",
	"id": 90,
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg">Novafin Star SRL se ocupă cu furnizarea și instalarea finisajelor de cea mai mare calitate. Gama de servicii oferite:</p>
                  <ol class="list-decimal list-inside md:px-5 mb-4 text-base md:text-lg">
                    <li class="mb-3 w-full md:text-lg list-deciaml"><b>Tencuieli Interioare:</b> folosirea tehnicilor moderne și a materialelor de încredere în scopul asigurării unui rezultat final de cea mai bună calitate.</li>
                    <li class="mb-3 w-full md:text-lg list-decimal"><b>Tencuieli Exterioare:</b> rezistente în timp și oferirea protecției împotriva vremii și a factorilor externi.</li>
                    <li class="mb-3 w-full md:text-lg list-decimal"><b>Glet de Ipsos:</b> finisaj interior, asigurând ca rezultat o suprafață netedă și bine finisată.</li>
                    <li class="mb-3 w-full md:text-lg list-decimal"><b>Zugrăveli Lavabile:</b> utilizarea vopselelor de înaltă calitate, care pot fi curățate fără probleme, permițându-vă să păstrați un aspect proaspăt și curat în interiorul casei.</li>
                  </ol>
                  <p class="mb-3 w-full md:text-lg">Transformă spațiile în căminuri primitoare și birouri pline de stil. Cu o abordare creativă și pricepută, fiecare proiect devine o poveste unică.</p>
                  <p class="mb-3 w-full md:text-lg">Pentru mai multe informații despre serviciile oferite sau pentru oferte personalizate, apelați 0745 357 905 sau la adresa de email office.novafin.star@gmail.com.</p>
                  <p class="mb-3 w-full md:text-lg">Le urăm mult succes în implementarea planului de afaceri!</p>
                  </div>
	</>
	)
}


export const options = {
	"title": "Oana Forest SRL – atelier de prelucrare a lemnului în Cugir",
	"category": "AFACERI",
	"category_link": "/projectsbusiness",
	"id": 70
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg">Oana Forest SRL oferă servicii de prelucrare a lemnului în Cugir, județul Alba. Localitatea nu este aleasă întâmplător, aceasta remarcându-se printr-o tradiție de lungă durată în prelucrarea lemnului, atât la nivel industrial cât și artizanal, dar și printr-o disponibilitate de materie primă, respectiv lemn pentru realizarea produselor.</p>
                  <p class="mb-3 w-full md:text-lg">Din punct de vedere economic și turistic, orașul Cugir oferă produse și servicii de prelucrare a lemnului la nivel primar, dar și specializat.</p>
                  <p class="mb-3 w-full md:text-lg">Oana Forest SRL reprezintă o baza de producție pentru fabricarea produselor din lemn destinate utilării construcțiilor și amenajării locuințelor:</p>
                  <ol class="list-decimal list-inside md:px-5 mb-4 text-base md:text-lg">
                    <li class="mb-3 w-full md:text-lg list-disc">grinzi, căpriori, suporturi pentru acoperiș</li>
                    <li class="mb-3 w-full md:text-lg list-disc">grinzi cu zăbrele prefabricate din lemn, impregnate cu clei și având adaosuri metalice</li>
                    <li class="mb-3 w-full md:text-lg list-disc">uși, ferestre, obloane și cadrele lor, cu sau fără accesorii metalice ca: balamale, zăvoare etc.</li>
                    <li class="mb-3 w-full md:text-lg list-disc">scări, balustrade</li>
                    <li class="mb-3 w-full md:text-lg list-disc">borduri și ornamente, șindrilă și șiță</li>
                    <li class="mb-3 w-full md:text-lg list-disc">fabricarea de construcții din lemn prefabricate, sau elemente ale acestora, predominant din lemn, ca de exemplu, saune</li>
                    <li class="mb-3 w-full md:text-lg list-disc">fabricarea caselor mobile</li>
                    <li class="mb-3 w-full md:text-lg list-disc">fabricarea pereților despărțitori din lemn (cu excepția celor autoportanți)</li>
                  </ol>
                  <p class="mb-3 w-full md:text-lg">Pentru mai multe informații despre serviciile oferite sau pentru oferte personalizate, contactați office.oana.forest@gmail.com.</p>
                  <p class="mb-3 w-full md:text-lg">Le urăm mult succes în implementarea planului de afaceri!</p>
                  </div>
	</>
	)
}


export const options = {
	"title": "MIAS BEAUTY – epilare definitivă în Alba Iulia",
	"category": "AFACERI",
	"category_link": "/projectsbusiness",
	"id": 69
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg">Societatea MIAS-Beauty S.R.L vă propune servicii de înfrumusețare și îngrijire corporală cu ajutorul unor echipamente care dispun de tehnologie de ultimă generație ce realizează epilarea definitivă.</p>
                  <p class="mb-3 w-full md:text-lg">Pentru mai multe informații despre serviciile oferite sau pentru oferte personalizate, contactați mias.beauty@protonmail.com. Le urăm mult succes în implementarea planului de afaceri!</p>
                  </div>
	</>
	)
}

export const options = {
	"title": "MIAS BEAUTY SRL – Centru de înfrumusețare",
	"category": "AFACERI",
	"category_link": "/projectsbusiness",
	"id": 97,
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg"><b>Mias Beauty SRL</b> oferă tehnologie de ultimă oră pentru rezultate optime cu un număr minim de proceduri aplicate cu disconfort fizic minim.</p>
                  <p class="mb-3 w-full md:text-lg">Servicii de epilare laser:</p>
                  <ol class="list-decimal list-inside md:px-5 mb-4 text-base md:text-lg">
                    <li class="mb-3 w-full md:text-lg list-disc">Epilare facială</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Epilare membre</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Epilare axilă</li>
                    <li class="mb-3 w-full md:text-lg list-disc">Epilare inghinală</li>
                  </ol>
                  <p class="mb-3 w-full md:text-lg">Procesul de epilare se realizează cu aparatul profesional de epilare definitivă DERMALASER.</p>
                  <p class="mb-3 w-full md:text-lg">Cel mai puternic laser diode cu cel mai ușor aplicator: 3 lungimi de unda: 755nm, 808nm, 1064nm, într-un singur echipament inovativ.</p>
                  <p class="mb-3 w-full md:text-lg">DermaLaser oferă beneficiile sinergetice ale celor 3 lungimi de undă eficiente pentru îndepărtarea părului, fiecare vizând structuri diferite din foliculul de păr.</p>
                  <p class="mb-3 w-full md:text-lg">Pentru servicii și prețuri accesați pagina web <a class="items-center text-lg underline hover:text-red-500" src="https://miasbeauty.ro/">https://miasbeauty.ro/</a> sau la nr. de telefon 0744 571 593.</p>
                  <p class="mb-3 w-full md:text-lg">Le urăm mult succes în implementarea planului de afaceri!</p>
                  </div>
	</>
	)
}


export const options = {
	"title": "Începe cursul de lucrător comercial",
	"category": "PROGRES",
	"category_link": "/projectsprogress",
	"id": 66
}

export default function Post(){
	return (
	<>
	<div class="accordion-content mt-4">
                  <p class="mb-3 w-full md:text-lg">Prima grupă de curs în domeniul lucrător comercial începe în data de 10 iulie. Cursul se va desfășura online pe o perioadă de 3 luni, urmând ca în luna octombrie participanții să susțină examenul de calificare. Cursul are atât o parte teoretică, cât și o parte practică ce se va desfășura la operatori comerciali din zonă.</p>
                  <p class="mb-3 w-full md:text-lg">Cursul este desfășurat în cadrul proiectului ”Creșterea ocupării, precum și îmbunătățirea nivelului de competențe al tinerilor NEETs” de către JAM BUSINESS SRL.</p>
                  <p class="mb-3 w-full md:text-lg">Le dorim mult succes participanților în procesul de învățare!</p>
                  </div>
	</>
	)
}
